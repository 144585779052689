html {
  font-size: 62.5%;
}

body {
  user-select: none;
}


hr {
  margin: 0 var(--iq-grid-spacing) var(--iq-grid-spacing);
}

//noinspection CssInvalidHtmlTagReference
nextjs-portal {
  user-select: text;
}

@font-face {
  font-family: "PlateNumber";
  font-weight: normal;
  //src: url("/fonts/plate-number.otf");
}

.radar-app {
  display: flex;
  flex-direction: column;

  > .iq-table {
    width: 100%;
    flex: 1;
  }

  &.no-paddings {
    padding: 0 !important;
  }

  &.no-scroll {
    .content-box {
      position: relative;

      .iq-table {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  h3 {
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }
}

img {
  width: 100%;
  height: 100%;
}

.status {
  background: var(--iq-divider);
  border-radius: var(--iq-input-border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 3.2rem;

  &.inline {
    display: inline-flex;
    padding: 0.2rem;
    //margin: -0.2rem;
    margin: 0.2rem;
    line-height: initial;
    cursor: pointer;
  }

  &.info {
    background: var(--iq-accent-secondary);
    color: var(--iq-accent-secondary-text);
  }

  &.success {
    background: var(--iq-accent);
    color: var(--iq-accent-text);
  }

  &.warning {
    background: var(--iq-warning);
    color: var(--iq-warning-text);
  }

  &.error {
    background: var(--iq-error);
    color: var(--iq-error-text);
  }
}

.iq-table {
  .iq-table-header {
    min-height: 4.4rem;
  }

  &.selectable {
    .iq-table-row {
      cursor: pointer;

      .iq-table-cell {
        transition: var(--iq-feedback-transition);
      }

      &:hover .iq-table-cell {
        background: var(--iq-darken);
        border-color: var(--iq-outline);
      }
    }
  }
}

.iq-table-cell {
  grid-gap: 0.6rem;
  overflow: hidden;

  .iq-checkbox {
    margin: 0;
    height: 1.9rem;
  }

  .iq-input {
    margin: 0;
    border: none;
    background: transparent;

    .bar {
      display: none;
    }
  }

  > span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .iq-button + .iq-button {
    margin: 0;
  }

  .status {
    position: relative;
    background: transparent !important;
    color: var(--iq-color) !important;
    flex: 1;
    height: 100%;
    //margin: -0.6rem;
    font-weight: normal;
    overflow: initial;

    --status-color: var(--iq-divider);

    &:before {
      content: '';
      position: absolute;
      left: -1.1rem;
      top: -1.1rem;

      //border-color: var(--status-color) transparent transparent var(--status-color);
      //border-style: solid;
      //border-width: 0.4rem 0.4rem 0.4rem 0.4rem;
      border: 0.6rem solid var(--status-color);
      border-radius: 50%;
      height: 0;
      width: 0;
    }

    & + .status {
      padding-left: 0;

      &:before {
        left: auto;
        right: -1.1rem;
      }
    }

    &.info {
      --status-color: var(--iq-accent-secondary);
    }

    &.success {
      --status-color: var(--iq-accent);
    }

    &.warning {
      --status-color: var(--iq-warning);
    }

    &.error {
      --status-color: var(--iq-error);
    }
  }
}

.plate-number {
  //noinspection CssNoGenericFontName
  //font-family: "PlateNumber";
  color: #666;
  width: 18rem;
  height: 3.2rem;
  font-size: 2rem;
  position: relative;
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
  line-height: 2.8rem;
  display: inline-block;
  border-radius: 0.4rem;
  padding: 0.2rem 1.2rem;
  box-sizing: border-box;
  border: 0.1rem solid #666;
  box-shadow: 0 0 0 0.1rem #f6f6f6;
  background-color: #f6f6f6;
  background-repeat: no-repeat;
  background-size: 0.3rem 0.3rem, 0.3rem 0.3rem;
  background-position: 0.2rem center, calc(100% - 0.2rem) center;
  background-image: radial-gradient(#666 50%, transparent 50%), radial-gradient(#666 50%, transparent 50%);

  &.plate-spec {
    padding-left: 5rem;
    background-size: 0.3rem 0.3rem, 0.3rem 0.3rem, 4.5rem;
    background-position: 0.2rem center, calc(100% - 0.2rem) center, 0 center;
    //background-image: radial-gradient(#ccc 50%, transparent 50%), radial-gradient(#ccc 50%, transparent 50%), url(/plateFlags/uz-spec.png);
  }

  &.plate-base {
    padding-right: 2.4rem;
    background-size: 0.8rem, 0.3rem 0.3rem, 0.3rem 0.3rem;
    background-position: calc(100% - 1rem) center, 0.2rem center, calc(100% - 0.2rem) center;
    //background-image: url(/plateFlags/uz.png), radial-gradient(#ccc 50%, transparent 50%), radial-gradient(#ccc 50%, transparent 50%);
  }

  &.plate-green {
    color: #fff;
    border-color: #fff;
    background-color: #24b14c;
    box-shadow: 0 0 0 0.1rem #24b14c;
    background-image: radial-gradient(#fff 50%, transparent 50%), radial-gradient(#fff 50%, transparent 50%);
  }

  &.plate-yellow {
    color: #333;
    border-color: #333;
    background-color: #ffc911;
    box-shadow: 0 0 0 0.1rem #ffc911;
    background-image: radial-gradient(#333 50%, transparent 50%), radial-gradient(#333 50%, transparent 50%);
  }

  &.plate-blue {
    color: #fff;
    border-color: #fff;
    background-color: #3f48cc;
    box-shadow: 0 0 0 0.1rem #3f48cc;
    background-image: radial-gradient(#fff 50%, transparent 50%), radial-gradient(#fff 50%, transparent 50%);
  }

  &.plate-black {
    color: #fff;
    border-color: #fff;
    background-color: #333;
    box-shadow: 0 0 0 0.1rem #333;
    background-image: radial-gradient(#fff 50%, transparent 50%), radial-gradient(#fff 50%, transparent 50%);
  }

  &.plate-other {
    background: #AAA;
    color: #000;
    border-color: #000;
    box-shadow: 0 0 0 0.1rem #AAA;
  }
}

.activity {
  max-width: 120rem;
  width: 100%;
  min-height: 80vh;
  transition: var(--iq-ui-transition);

  > .iq-view-content {
    min-height: 80vh;
  }

  .iq-table {
    width: 100%;
    border: none;

    .iq-view-content {
      height: auto;
      max-height: none;
    }
  }

  .iq-overflow-container {
    overflow: hidden;
  }

  .iq-modal-content {
    padding: 0;
    height: 100%;
    display: flex;
    overflow: hidden;
  }
}

.dialog {
  &.fullscreen {
    .iq-modal-content {
      padding: 0;
    }

    .dialog-content {
      .content-box {
        height: 100%;
      }
    }
  }
}

.radar-app-window {
  &.iq-light, &.iq-dark {
    --iq-grid-spacing: 1.6rem;
    --iq-nav-width: 20rem;
  }

  > .iq-window-content > .iq-document-wrapper > .iq-header-wrapper.hidden {
    transform: translateY(-4.4rem);
  }

  .app-title {
    display: flex;

    img {
      height: 2.4rem;
      width: auto;
      margin-right: 0.8rem;
    }

    .title-text {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0.8rem;
    }

    //> div:last-child {
    //  opacity: 0.7;
    //
    //  .iq-button {
    //    margin: 0;
    //  }
    //
    //  div {
    //    font-size: 80%;
    //    display: inline-block;
    //    padding-left: 0.8rem;
    //    text-transform: capitalize;
    //    line-height: 3.6rem;
    //  }
    //}
  }

  .app-subtitle {
    display: flex;

    > div:first-child {
      flex: 1;
    }

    > b {
      line-height: 1;
      align-self: end;
    }
  }
}

.iq-header-title {
  h5 > div.app-title {
    line-height: 0;
  }
}

.iq-header-extra span {
  display: flex;
  justify-content: center;

  .iq-image {
    margin-left: var(--iq-grid-spacing);
  }
}

//.iq-input.required {
//  border-left: none !important;
//
//  &.required {
//    border-color: var(--iq-warning);
//
//    > .bar {
//      &:before, &:after {
//        background: var(--iq-error);
//      }
//    }
//  }
//}

.location-input-map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 24rem;
  flex: 1;
  margin-bottom: 1.6rem;
  border-radius: var(--iq-input-border-radius);
  overflow: hidden;

  .cross {
    left: 50%;
    bottom: 50%;
    font-size: 0;
    width: 4rem;
    margin: -3.2rem 0 0 -2rem;
    text-align: center;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      border: 0.2rem solid var(--iq-backdrop);
      margin: 0 0 -0.2rem -0.2rem;
      border-radius: 50%;
      transition: var(--iq-ui-transition);
    }

    &.preview {
      span {
        bottom: 2.4rem;
      }

      &:before {
        border-width: 0.4rem;
        margin: 0 0 -0.4rem -0.4rem;
      }
    }

    span {
      color: var(--iq-color-secondary);
      width: 4rem;
      position: absolute;
      line-height: 0.8;
      font-size: 3.4rem;
      text-shadow: var(--iq-elevation-1-shadows);
      bottom: 0;
      margin-left: -2rem;
      transition: var(--iq-ui-transition);
    }
  }

  > div {
    position: absolute !important;
  }
}

.map-wrapper {
  position: relative;
  flex: 1;
  pointer-events: none;

  &.interactive {
    pointer-events: auto;
  }

  > div {
    position: absolute !important;
  }

  .controls {
    &.bottom-right {
      display: flex;
      position: absolute;
      right: var(--iq-grid-spacing);
      //bottom: var(--iq-grid-spacing);
      border-radius: var(--iq-input-border-radius);
      overflow: hidden;
      background: var(--iq-background);
      border: var(--iq-input-border);
      bottom: 270px;
    }

    .iq-button {
      margin: 0;
      width: 3.2rem;
      height: 3.2rem;
      padding: 0;
      border-radius: 0;
      border-color: transparent;
    }
  }
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

.station-marker {
  display: flex;
  width: 22px;
  height: 22px;
  font-size: 14px;
  box-sizing: border-box;
  background: var(--iq-success);
  color: var(--iq-background);
  border-radius: 50%;
  box-shadow: var(--iq-elevation-1-shadows);
  justify-content: center;
  align-items: center;
  cursor: pointer;

  //&:before {
  //  position: absolute;
  //  content: '';
  //  left: 0;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  background: var(--iq-error);
  //  opacity: 0;
  //  border-radius: 50%;
  //  pointer-events: none;
  //}

  //&.flash {
  //  &:before {
  //    opacity: 1;
  //  }
  //}
  //
  //&.flash-anim {
  //  &:before {
  //    left: -2rem;
  //    top: -2rem;
  //    right: -2rem;
  //    bottom: -2rem;
  //    opacity: 0;
  //    transition: all 0.218s linear;
  //  }
  //}

  /*
  NO_DEVICE
  NO_NETWORK
  NO_POWER
  NO_CONSTRUCTION
   */

  &.no_device, &.no_network, &.no_power, &.no_construction {
    border-radius: 0.1rem;
    color: #FFF;
  }

  &.no_device {
    background: #ffa600;
  }

  &.no_network {
    background: #ff6361;
  }

  &.no_power {
    background: #bc5090;
  }

  &.no_construction {
    background: #58508d;
  }

  &.unconfigured {
    //opacity: 0.5;
    //background: var(--iq-color-secondary);
    background: #d500f9;
    color: #FFF;
    border-color: var(--iq-background) !important;
    border-radius: 0.2rem;
  }

  &.inactive {
    border-radius: 0.6rem;
  }

  &.inactive, &.idle {
    background: var(--iq-color-secondary);
    color: var(--iq-background);
    border-color: var(--iq-background) !important;
  }

  &.unavailable {
    background: var(--iq-error);
    color: var(--iq-error-text);
    border-color: var(--iq-error-text) !important;
  }

  &.software_issues {
    background: #d500f9;
    color: #FFF;
    border-color: var(--iq-error-text) !important;
  }

  &.inaccessible {
    background: var(--iq-warning);
    color: var(--iq-warning-text);
    border-color: var(--iq-warning-text) !important;
  }

  &.active {
    background: var(--iq-success);
    color: var(--iq-success-text);
    border-color: var(--iq-success-text) !important;
  }

  &.selected {
    background: var(--iq-accent-secondary);
    color: var(--iq-accent-secondary-text);
    border-color: var(--iq-accent-secondary-text) !important;
  }
}

.iq-dark {
  .station-marker {
    border: 1px solid var(--iq-divider);
  }
}

.user-avatar {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  font-weight: bold;
  background: var(--iq-divider);
  cursor: pointer;
  border: 0.2rem solid var(--iq-divider);
  transition: var(--iq-feedback-transition);

  &.no-border {
    border: none;
  }

  &.big {
    width: 11.6rem;
    height: 11.6rem;
    border: none;
    cursor: default;
    border-radius: var(--iq-input-border-radius);
    box-shadow: var(--iq-elevation-2-shadows);
  }

  &:hover {
    border-color: var(--iq-accent-secondary);
  }
}

.table-user-avatar {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: var(--iq-input-border-radius);
  font-weight: bold;
  background: var(--iq-divider);
  margin-right: 0.6rem;
}

.content-box {
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: var(--iq-ui-transition);
  padding: 0;

  &.center {
    text-align: center;
    align-items: center;
  }

  canvas {
    width: 100%;
    height: auto;
  }

  &.small {
    min-width: 48rem;
    max-width: 480px;
    margin: 0 auto;
    padding: 1.6rem;
  }

  > .iq-table {
    flex: 1;
    border-radius: 0;
    border: none;
  }

  @media only screen and (max-width: 750px) {
    &.small {
      min-width: unset;
    }
  }
}

.app-header-logo {
  height: 3.5rem;
  width: auto;

  path {
    fill: var(--iq-color);
  }
}

.map-footer {
  padding: 0;
  display: flex;
  height: 13.4rem;

  .iq-layout {
    margin-bottom: 0;
    justify-content: center;
    min-width: 15rem;
  }

  .records {
    flex: 1;
  }

  .stats {
    display: flex;
    flex-direction: column;
    width: 12rem;
    flex: initial;
    align-items: center;
    border-right: 0.1rem solid var(--iq-outline);

    span:nth-child(2), span:nth-child(3) {
      font-weight: 700;
    }

    span:nth-child(3) {
      color: var(--iq-accent-secondary);
    }
  }

  @media only screen and (max-width: 640px) {
    & {
      flex-direction: column;
      height: 15.8rem;
      text-align: center;

      .records {
        padding-top: 0;
      }

      .stats {
        width: 100%;
        flex-direction: row;
        height: 3.2rem;
        border: none;
        padding: 0;

        span:nth-child(1) {
          min-width: 16rem;
        }

        span:nth-child(2), span:nth-child(3) {
          flex: 1;
        }
      }
    }
  }
}

.records {
  display: flex;
  flex-direction: row;
  overflow: auto;
  grid-gap: 0.8rem;
  line-height: 1;
  overflow-x: scroll;
  padding: 0.8rem;
  height: 13.4rem;

  .record {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--iq-outline);
    border-radius: var(--iq-input-border-radius);
    box-shadow: var(--iq-elevation-1-shadows);
    height: 10.8rem;
    min-width: 32.4rem;

    img {
      width: 12.8rem;
      height: 10.8rem;
      border-radius: var(--iq-input-border-radius);
    }

    .data {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 0.8rem;
      padding: 0.8rem;
    }

    .type b {
      margin-right: 0.8rem;
    }
  }
}

.device-monitor {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .records {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fit, minmax(32.4rem, 1fr));
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  .device-video {
    background: black;
    width: 100%;
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center;
    justify-content: center;

    canvas {
      width: 100%;
      height: auto;
    }
  }

  @media (orientation: landscape) {
    & {
      flex-direction: row;

      .records {
        min-width: 35rem;
        flex: 0;
      }

      .device-video {
        flex: 1;
        aspect-ratio: auto;
      }
    }
  }
}

.cloud-loader {
  color: var(--iq-color-secondary);
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.4rem;
  letter-spacing: 4px;
  box-sizing: border-box;
  position: absolute;

  &.hidden {
    display: none;
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 2.2rem;
    bottom: 1.1rem;
    height: 1.4rem;
    width: 0.2rem;
    background: currentColor;
  }

  &:after {
    content: '';
    width: 0.4rem;
    height: 0.4rem;
    position: absolute;
    left: 2.1rem;
    top: 0.7rem;
    border-radius: 50%;
    background: var(--iq-accent-secondary);
    box-sizing: border-box;
    animation: cloud-loader-rotation 1s ease-in infinite;
  }
}

@keyframes cloud-loader-rotation {
  0% {
    transform: translateY(0rem) scaleY(1) scaleX(1.25);
  }
  25%, 75% {
    transform: translateY(-0.8rem) scaleY(1.2) scaleX(1);
  }
  50% {
    transform: translateY(-1rem) scaleY(1) scaleX(1);
  }
  100% {
    transform: translateY(0rem) scaleY(0.8) scaleX(0.8);
  }
}

.chart-box {
  border-radius: var(--iq-input-border-radius);
  box-shadow: var(--iq-elevation-1-shadows);
  background: var(--iq-darken);
  margin-bottom: var(--iq-grid-spacing);
  //padding: var(--iq-grid-spacing);
}

.chart-box-title {
  text-align: center;
  font-weight: 700;
  padding: 0.8rem;

  @media (max-width: 550px) {
    margin: 0;
  }
}

.captcha {
  background: #FFF;
  border-radius: var(--iq-input-border-radius);
  box-shadow: var(--iq-elevation-1-shadows);
  margin-bottom: var(--iq-grid-spacing);
}

.center {
  text-align: center;
}

.moderator-plate-number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--iq-darken);
  border-radius: var(--iq-input-border-radius);

  &.video {
    height: auto;

    video {
      width: calc(100% - 3.2rem);
      height: auto;
      margin: 1.6rem;
      aspect-ratio: 1504 / 949;
      background-color: black;
      border-radius: var(--iq-input-border-radius);
    }
  }

  picture {
    max-width: 32rem;
    line-height: 1;
    margin: 1.6rem;
    display: flex;
    flex-direction: column;
  }

  video + picture {
    margin-top: 0;
  }

  img {
    width: 100%;
    height: auto;
    //max-height: 16rem;
    border-radius: var(--iq-input-border-radius);
  }
}

.moderator-plate-input {
  font-size: 300%;

  input {
    text-align: center;
    user-select: unset;
  }
}

.modal-record-media {
  .iq-overflow-container {
    display: flex;
  }

  .iq-modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: var(--iq-grid-spacing);
    max-width: 120rem;
    margin: 0 auto;

    > .iq-row-tablet {
      .image, .video {
        background: black;
        overflow: hidden;
        position: relative;
        margin-bottom: var(--iq-grid-spacing);

        img, video {
          object-fit: cover;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.plate-number-font {
  text-transform: uppercase;
  font-size: 3.5rem;
  line-height: 1;
  margin-top: var(--iq-grid-spacing);

  @media only screen and (max-width: 413px) {
    font-size: 2rem;
  }
}

.plate-font {
  text-transform: uppercase;
  //font-size: 3.5rem;
  line-height: 1;

  color: var(--iq-background);
  background: var(--iq-color);
  padding: 0.2rem 0.4rem;
  font-size: 2rem;
  border-radius: var(--iq-input-border-radius);
  border: 0.2rem solid #000;
}

.plate-item .iq-item-content {
  display: flex;
  justify-content: center;

  .iq-item-title {
    line-height: 1;
  }
}

.device-address {
  //left: 0;
  //bottom: 0;
  //right: 0;

  min-height: 4rem;
  margin-left: calc(-1 * var(--iq-grid-spacing));
  margin-right: calc(-1 * var(--iq-grid-spacing));
  margin-bottom: var(--iq-grid-spacing);
  background: var(--iq-divider);
  padding: calc(var(--iq-grid-spacing) / 2);
  //position: absolute;
  //z-index: 5;
  text-shadow: 0 0 0.5rem var(--iq-);
  font-weight: 600;
  display: flex;

  .iq-single-line {
    flex: 1;
    margin: 0 0.6rem;
  }
}

.iq-text-accent {
  color: var(--iq-background);
  background: var(--iq-color);
  padding: 0.2rem 0.4rem;
  margin: -0.2rem 0;
  border-radius: var(--iq-input-border-radius);
}

.moderator-help-icon {
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;

  & > :first-child {
    line-height: 0;
    font-size: 3.2rem;
    color: var(--iq-color);
    opacity: 0.6;

    &:hover + * {
      opacity: 1;
    }
  }

  & > :last-child {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 2.4rem;
    padding: 0.8rem;
    border-radius: var(--iq-input-border-radius);
    background: var(--iq-background);
    transition: var(--iq-feedback-transition);
  }
}

.pick-device {
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
  margin: 0;
}


// moderation-stats
.iq-paper {
  border-radius: var(--iq-input-border-radius);
  background: var(--iq-background);
  box-shadow: var(--iq-elevation-1-shadows);
  overflow: hidden;
}

.chart-container:fullscreen {
  overflow-y: scroll;
  pointer-events: none;

  .eye-icon-container {
    display: none;
  }
}

.chart-container:-ms-fullscreen {
  overflow-y: scroll;
  pointer-events: none;

  .eye-icon-container {
    display: none;
  }
}

.chart-container:-webkit-full-screen {
  overflow-y: scroll;
  pointer-events: none;

  .eye-icon-container {
    display: none;
  }
}

.chart-container:-moz-full-screen {
  overflow-y: scroll;
  pointer-events: none;

  .eye-icon-container {
    display: none;
  }
}

.chart-container {
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 250px));
  gap: 1rem;
  width: 100%;
  background: var(--iq-background);
  padding: .5rem;

  .moderation-user-photo {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .moderations {
    &-numbers {
      font-size: 100%;
      color: var(--iq-color);
      font-weight: 500;
      opacity: .5;
      margin-top: .5rem;
      padding: .5rem;
      text-align: center;
      display: flex;
    }
  }

  .photo-user {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .reg-number-photo-box {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-self: center;
    padding: .5rem;
  }

  .reg-number-photo {
    height: auto;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    mix-blend-mode: screen;
  }
}

@media only screen and (max-width: 595px) {
  .chart-container {
    justify-content: center;
  }
}

@media only screen and (max-width: 265px) {
  .chart-container {
    display: block;
    gap: 0;
  }
}

// moderation-stats

// Statisticts
#chart-text {
  color: var(--iq-color) !important;
}

// Statisticts


// Verification
.verification-dialog {
  min-width: clamp(370px, 50rem, 1800px);

  .dialog-container {
    max-width: 90vw;
    margin: 2rem auto;
  }
}

.drop-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dotted var(--iq-color);
  width: 100%;
  border-radius: 5px;
  cursor: pointer;

  > .iq-input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    opacity: 0;

    label, > div {
      display: none;
    }

    input[type="file"] {
      position: absolute;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  .iq-icon {
    font-size: 200%;
    margin-top: var(--iq-grid-spacing);
  }

}

.selected-images-container {
  max-width: 35rem;
  overflow: auto;

  .select-images {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: var(--iq-backdrop);
    margin: .3rem;
    border-radius: 5px;
    padding: .3rem;

    .image-box {
      display: flex;
      flex-direction: column;
      padding: 0 2rem 0 .2rem;


      .image-name {
        width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .remove-icon {
      position: absolute;
      color: #ee2424;
      cursor: pointer;
      margin: 0 .5rem;
      right: 0;
      top: 0;
    }

  }
}

.drop-input__leave-border {
  border: 1px dotted var(--iq-accent-secondary);
}

@media only screen and (max-width: 750px) {
  .selected-images-container {
    max-width: 100%;
  }
}

.history-btn {
  position: absolute;
  bottom: 0;
  right: 5rem;
  z-index: 10;
}

// Verification

.modalBuss {
  display: flex;
  align-content: center;
  padding: 0 2rem;
}

.bus-info {
  display: flex;
  align-items: center;
}

.arrival-time {
  display: flex;
  align-items: center;
}

.segment-display {
  display: flex;
}

.segment {
  width: 1.5rem;
  height: 2rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-right: 0.2rem;
}

.segment span {
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: #009688;
  border-radius: 0.1rem;
}

.segment.colon {
  width: 0.5rem;
  border: none;
  margin: 0 0.2rem;
}

.on {
  background-color: #ffa500;
}


.station-marker-error {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red !important;
  color: #fff;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  position: relative;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  transform: scale(1.2);
}

.station-marker-warning {
  color: #fff !important;
  background: orange !important;
}

.station-marker-error::before {
  pointer-events: none;
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.5);
  animation: ripple 1s infinite;
  animation-timing-function: ease-out;
}

.info-none {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 100%;
}

.station-marker-pulsing-error::before {
  content: '';
  pointer-events: none;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(255, 165, 0, 0.5);
  animation: ripples 1s infinite;
  animation-timing-function: ease-out;
}

.station-marker-pulsing-error {
  background-color: orange !important;
  color: #fff !important;
}

@keyframes ripples {
  0% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(4.5);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(4.5);
    opacity: 0;
  }
}

.routes-preview {
  overflow-x: auto;
  white-space: nowrap;
}

.routes-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 0;
  border-radius: 8px;
  height: 260px;
}

.route-group {
  display: flex;
  flex-direction: column;
  min-width: calc(50% - 16px);
  padding: 6px 4px;
  margin: 8px 0;
  background-color: #2c2a31;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.route {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  margin: 2px;
  background-color: #39353e;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-buss {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.bus-icon {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.route-number {
  color: #fff;
  font-weight: bold;
  flex: 1;
}

.time-container {
  display: flex;
  align-items: center;
}

.time-icon {
  display: flex;
  align-items: center;
  background-color: #302933;
  border-radius: 3px;
  padding: 4px 10px;
  margin-left: 8px;
}

.route-remaining {
  color: #fff;
  font-weight: bold;
  margin-left: 4px;
  width: 25px;
  text-align: center;
}

.info-stations {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 8px 0;
  align-items: center;
  font-weight: bold;
  height: 252px;
}

@media only screen and (max-width: 650px) {
  .route-group {
    flex: 0 0 100%;
  }
  .map-wrapper .controls.bottom-right {
    bottom: 270px;
  }
}

.station-marker-no-meta {
  background: #711195 !important;
  color: #fff!important;
}

:root, [data-theme=custom], [data-theme=dark], [data-theme=light] {
  --iq-nav-width: 18rem
}

.status_button {
  width: 100%;
}

.map-wrapper .all-stations {
  display: flex;
  position: absolute;
  right: var(--iq-grid-spacing);
  border-radius: var(--iq-input-border-radius);
  overflow: hidden;
  background: var(--iq-background);
  border: var(--iq-input-border);
  bottom: 10px !important;
}








