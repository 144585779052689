/* ErrorLog.css */
.error-log-container {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  background-color: #2c2a31;
  border-radius: 0.5rem;
}

.error-card {
  background-color: #2c2a31;
  color: #ffffff;
  border: 1px solid #3a3a3a;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
}

.error-card:hover {
  background-color: #3a3a3a;
  transform: translateY(-5px);
}

.error-title {
  font-size: 1.6rem;
  font-weight: bold;
}

.error-subtitle {
  font-size: 1.4rem;
  color: #ff4d4d;
  font-weight: 600;
}

.error-card::before {
  content: "!";
  font-size: 2.5rem;
  font-weight: bold;
  color: #ff4d4d;
  position: absolute;
  top: 50%;
  left: -2rem;
  transform: translateY(-50%);
  background-color: #2c2a31;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

