.bus-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 1rem;
}

.bus-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
  background-color: var(--iq-darken);
  border-radius: 8px;
  box-shadow: var(--iq-elevation-2-shadows);
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: var(--iq-color);
  width: calc(50% - 2rem);
  box-sizing: border-box;
}

.bus-item.single {
  width: 100%;
}

.bus-item:hover {
  background-color: var(--iq-outline);
  transform: translateY(-2px);
}

.bus-item.error {
  background-color: var(--iq-error);
}

.bus-info, .arrival-time {
  display: flex;
  align-items: center;
}

.bus-info b, .arrival-time span {
  font-size: 1.6rem;
}

.arrival-time {
  width: 50px;
}

.icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.bus-item.error .arrival-time .icon {
  color: var(--iq-error-text);
}

@media (max-width: 600px) {
  .bus-item {
    width: 100%;
  }
}

.station-info {
  text-align: center;
  padding: 1rem;
  background-color: var(--iq-darken);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.status-information {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  padding: 0.2rem 0.6rem;
  color: var(--iq-color);
  background: none !important;
}

.copy-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;
}

.copy-buttons button {
  background-color: var(--iq-success);
  color: var(--iq-success-text);
  flex: 1;
}

.copy-buttons button:last-child {
  background-color: var(--iq-accent-secondary);
  color: var(--iq-accent-secondary-text);
}

.bus-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--iq-darken);
  border-radius: 0.3rem;
}

.bus-item.error {
  background-color: var(--iq-error-hover);
}

.bus-info {
  display: flex;
  align-items: center;
}

.arrival-time {
  display: flex;
  align-items: center;
}

.arrival-time .icon {
  margin-right: 0.5rem;
}

.main-modal-map .iq-view-content {
  min-width: 50rem !important;
}
