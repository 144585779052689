.station-events {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #2c2a3178;
  bottom: 0;
  box-shadow: 0 -5px 10px #00000080;
  box-sizing: border-box;
  display: flex;
  height: 250px;
  overflow-x: auto;
  padding: 1rem;
  position: absolute;
  width: 100%;
}

.station-events::-webkit-scrollbar {
  height: 5px;
}

.station-events::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.station-events::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.station-events::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.event-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  padding: 1rem;
  margin:0 1rem 0 0;
  min-width: 300px;
  max-width: 355px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease;
}

.event-item.inactive {
  background-color: rgba(196, 9, 9, 0.19);
}

.event-item.warning {
  background-color: rgba(255, 165, 0, 0.5);
}

.event-item.pulsing-error {
  background-color: orange;
  color: #fff;
  animation: pulse 1.5s infinite;
}

.event-details {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}

.event-title {
  font-weight: bold;
  font-size: 1.6rem;
  color: #fff;
}

.event-date-time {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 800;
  justify-content: space-between;
  padding: 0 0.6rem;
}

.event-date {
  font-size: 1.4rem;
}

.event-time {
  font-size: 1.4rem;
}

.event-routes {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.event-route {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.2rem;
  flex: 1 1 calc(50% - 0.5rem);
  box-sizing: border-box;
}

.route-info {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.4rem;
  border-radius: 0.5rem;
  width: 100%;
  justify-content: space-between;
}

.route-info .bus-icon,
.route-info .time-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border-radius: 0.3rem;
  min-width: 35px;
  max-width: 35px;
  font-size: 1.4rem;
}

.route-info .bus-icon {
  background-color: #4caf50;
}

.route-info .time-icon {
  background-color: #ff9800;
  margin-left: 0.5rem;
}

.route-info .time-icon.error {
  background-color: #f44336;
}

.route-number {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.route-time {
  flex: 1;
  text-align: center;
}

.no-routes {
  font-size: 1.4rem;
  color: #fff;
  padding: 1rem;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

.no-stations {
  font-size: 1.4rem;
  color: #fff;
  padding: 1rem;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.error-button {
  position: absolute;
  top: 6rem;
  right: 1rem;
  background-color: rgba(255, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.error-button:hover {
  background-color: rgba(255, 0, 0, 0.9);
}

.error-button:hover .error-messages {
  display: block;
}

.error-button-icon {
  color: white;
  font-size: 1.5rem;
}

.error-messages {
  display: none;
  position: absolute;
  top: 3.5rem;
  right: 0;
  background-color: rgb(44, 42, 49);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 700px;
  max-height: 800px;
  overflow-y: auto;
  transition: opacity 0.3s;
  opacity: 0;
  align-items: center;
}

.error-button:hover .error-messages {
  opacity: 1;
}

.error-item {
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.8rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  transition: background-color 0.3s, transform 0.3s;
  align-items: center;
}

.error-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.02);
}

.error-item:last-child {
  margin-bottom: 0;
}

.error-icon {
  margin-right: 0.5rem;
  color: #ff6b6b;
  flex-shrink: 0;
}

.error-key {
  font-weight: bold;
  color: #ff6b6b;
  margin-right: 0.5rem;
}

.error-description {
  color: #e6e6e6;
}

@media (max-width: 600px) {
  .error-messages {
    width: 90%;
    max-height: 300px;
    top: 3rem;
    right: 5%;
  }
  .station-events {
    overflow-y: auto;
    width: 100%;
  }

  .error-button {
    top: 6rem;
    right: 1.3rem;
    padding: 0.6rem;
  }

  .error-button-icon {
    font-size: 1.2rem;
  }
}
